import * as React from 'react';

import ArticleGrid from '../components/ArticleGrid';

export interface RelatedPostsProps {
	posts: WPCard[];
}

export const RelatedPosts: React.SFC<RelatedPostsProps> = (props) => {
	return (
		<div className="blog-related-posts">
			<h3 className="text-display">Related Articles</h3>

			<ArticleGrid articles={props.posts} />
		</div>
	);
};

export default RelatedPosts;
