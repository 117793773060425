import * as React from 'react';

import { Link } from 'gatsby';
import { Card, Icon } from '@ao-internal/components-react';

export interface ArticleCardProps {
	tags?: {
		nodes: PostTag[];
	};
	title: string;
	excerpt: string;
	date: string;
	author: {
		node: PostAuthor;
	};
	slug: string;
}

export const ArticleCard: React.SFC<ArticleCardProps> = (props) => {
	return (
		<Card className="blog-card h-full">
			<div className="blog-card__tags">
				{props.tags &&
					props.tags.nodes.slice(0, 3).map((tag) => (
						<Link
							className="inline-block tag"
							key={tag.name}
							to={'/tag/' + tag.slug}>
							{tag.name}
						</Link>
					))}
			</div>
			<Link to={'/post/' + props.slug} className="blog-card__title-link">
				<h3
					className="text-display-sm"
					dangerouslySetInnerHTML={{ __html: props.title }}
				/>
			</Link>
			<div
				className="blog-card__excerpt"
				dangerouslySetInnerHTML={{ __html: props.excerpt }}
			/>
			<div className="blog-card__info">
				<div className="flex items-center author__container">
					<div className="flex-grow">
						<span className="block text-gray-70">
							<Icon
								size="2x"
								icon="calendar"
								className="pr-2 inline-block align-middle"
								label="Date"
							/>
							<span className="inline-block align-middle text-caption">
								{props.date}
							</span>
						</span>
					</div>
					<div className="flex-shrink text-right">
						<div
							className="author__avatar"
							style={
								props.author.node.avatar && {
									backgroundImage: `url(${props.author.node.avatar.url})`,
								}
							}
						/>
					</div>
				</div>
			</div>
		</Card>
	);
};

export default ArticleCard;
