import * as React from 'react';

import {
	Container,
	Grid,
	GridItem,
	Card,
	Icon,
} from '@ao-internal/components-react';

import {
	EmailIcon,
	EmailShareButton,
	FacebookIcon,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	TwitterIcon,
	TwitterShareButton,
} from 'react-share';
import { scrollTracking, sendGtagEvent } from '../helpers/helpers';

import Helmet from 'react-helmet';
import Img from 'gatsby-image';
import Layout from '../components/Layout';
import { Link } from 'gatsby';
import Prism from 'prismjs';
import RelatedPosts from '../components/RelatedPosts';
import { decode } from 'he';
import { graphql } from 'gatsby';

import 'prismjs/components/prism-c';
import 'prismjs/components/prism-csharp';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-graphql';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-scss';
import 'prismjs/components/prism-typescript';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-docker';

export interface PostTemplateProps {
	data: {
		wpPost: WordpressPost;
		relatedPosts: {
			edges: {
				node: WPCard;
			}[];
		};
		wp: {
			generalSettings: {
				title: string;
			};
		};
	};
	pageContext: {
		prev: WPCard;
		next: WPCard;
	};
	location: {
		pathname: string;
	};
}

export const pageQuery = graphql`
	query($id: String!, $related: [String]) {
		wpPost(id: { eq: $id }) {
			title
			excerpt
			content
			date(formatString: "MMM DD, YYYY")
			author {
				node {
					name
					avatar {
						url
					}
				}
			}
			featuredImage {
				node {
					localFile {
						childImageSharp {
							fluid {
								src
								srcSet
								aspectRatio
								sizes
							}
						}
					}
				}
			}
			categories {
				nodes {
					name
				}
			}
			tags {
				nodes {
					id
					name
					slug
				}
			}
		}
		relatedPosts: allWpPost(
			sort: { order: DESC, fields: date }
			limit: 3
			filter: {
				id: { ne: $id }
				tags: { nodes: { elemMatch: { slug: { in: $related } } } }
			}
		) {
			edges {
				node {
					title
					excerpt
					slug
					date(formatString: "MMM DD, YYYY")
					categories {
						nodes {
							name
							link
						}
					}
					tags {
						nodes {
							name
							slug
						}
					}
					author {
						node {
							id
							name
							avatar {
								url
							}
						}
					}
				}
			}
		}
		wp {
			generalSettings {
				title
			}
		}
	}
`;

export class PostTemplate extends React.PureComponent<PostTemplateProps, {}> {
	contentRef = React.createRef<HTMLDivElement>();

	componentDidMount() {
		Prism.highlightAllUnder(this.contentRef.current!);
		scrollTracking();
	}

	render(): React.ReactNode {
		const post = this.props.data.wpPost;

		let pageUrl = this.props.location.pathname;
		if (typeof window !== 'undefined') {
			pageUrl = window.location.href;
		}

		const htmlTagsRegex = /(<([^>]+)>)/gi;
		const postTitle = decode(post.title);
		const postExcerpt = decode(post.excerpt).replace(htmlTagsRegex, '').trim();

		const postHost =
			typeof window !== 'undefined'
				? window.location.origin
				: this.props.location.pathname;
		const postThumbUrl =
			post.featuredImage &&
			post.featuredImage.node?.localFile?.childImageSharp?.fluid
				? post.featuredImage.node?.localFile?.childImageSharp?.fluid?.src
				: '';
		const postThumb = `${postHost}${postThumbUrl}`;

		return (
			<React.Fragment>
				<Helmet>
					<title>{decode(post.title)}</title>
					<meta name="description" content={postExcerpt} />
					<meta property="og:title" content={postTitle} />
					<meta property="og:description" content={postExcerpt} />
					<meta property="og:image" content={postThumb} />
					<meta property="og:url" content={pageUrl} />
					<meta
						property="og:site_name"
						content={this.props.data.wp.generalSettings.title}
					/>
					<meta name="twitter:card" content="summary" />
					<link rel="canonical" href={pageUrl} />
				</Helmet>

				<Container>
					<article className="blog-post">
						<Card className="blog-post__container">
							{post.featuredImage &&
								post.featuredImage.node?.localFile?.childImageSharp?.fluid && (
									<Img
										className="blog-post__featured-image"
										fluid={
											post.featuredImage.node?.localFile?.childImageSharp?.fluid
										}
										alt={post.title}
										onError={(err) => {
											console.log(err);
										}}
									/>
								)}

							<h1
								className="text-display text-center blog-post__header"
								dangerouslySetInnerHTML={{ __html: post.title }}
							/>

							<div className="blog-post__contents text-body">
								<div className="items-center blog-post__body">
									<div className="blog-post__info">
										<div className="flex">
											<div className="flex-1">
												<div className="flex items-center author__container">
													<div className="flex-shrink">
														<figure>
															<img
																className="author__avatar"
																src={
																	post.author.node.avatar &&
																	post.author.node.avatar.url
																}
															/>
														</figure>
													</div>
													<div className="flex-grow">
														<span className="block author__name author">
															{post.author.node.name}
														</span>
														<span className="block text-gray-70 date">
															{post.date}
														</span>
													</div>
												</div>
											</div>
											<div className="flex-1 md:text-right blog-post__tags">
												{post.tags &&
													post.tags.nodes.map((tag) => (
														<Link
															className="inline-block tag"
															key={tag.name}
															to={'/tag/' + tag.slug}>
															{tag.name}
														</Link>
													))}
											</div>
										</div>
									</div>

									<div
										className="blog-post__tex text-body"
										dangerouslySetInnerHTML={{ __html: post.content }}
										ref={this.contentRef}
									/>

									<div className="blog-post__info">
										<Container className="px-0">
											{(this.props.pageContext.prev ||
												this.props.pageContext.next) && (
												<Grid className="items-center pt-4 blog-post__navigation">
													<GridItem
														key="1"
														span={{ def: 12, md: 4 }}
														className="text-left navigation__prev">
														{' '}
														{this.props.pageContext.prev && (
															<Link
																className="flex items-center justify-start text-link"
																to={
																	'/post/' + this.props.pageContext.prev.slug
																}>
																<Icon
																	icon="chevron-left"
																	label="Previous article"
																/>
																<span className="ml-4">Previous Article</span>
															</Link>
														)}
													</GridItem>
													<GridItem
														key="2"
														span={{ def: 12, md: 4 }}
														className="text-center blog-post__social">
														<div className="blog-post__social-network">
															<div className="blog-post__social-network__share-button">
																<FacebookShareButton
																	url={pageUrl}
																	quote={postTitle}
																	beforeOnClick={() => {
																		sendGtagEvent(
																			'Share Icon',
																			'Facebook',
																			postTitle,
																			0
																		);
																		return Promise.resolve();
																	}}>
																	<FacebookIcon size={32} round />
																</FacebookShareButton>
															</div>
														</div>

														<div className="blog-post__social-network">
															<div className="blog-post__social-network__share-button">
																<TwitterShareButton
																	url={pageUrl}
																	title={postTitle}
																	beforeOnClick={() => {
																		sendGtagEvent(
																			'Share Icon',
																			'Twitter',
																			postTitle,
																			0
																		);
																		return Promise.resolve();
																	}}>
																	<TwitterIcon size={32} round />
																</TwitterShareButton>
															</div>
															<div className="blog-post__social-network__share-count">
																&nbsp;
															</div>
														</div>

														<div className="blog-post__social-network">
															<div className="blog-post__social-network__share-button">
																<LinkedinShareButton
																	url={pageUrl}
																	windowWidth={750}
																	windowHeight={600}
																	beforeOnClick={() => {
																		sendGtagEvent(
																			'Share Icon',
																			'LinkedIn',
																			postTitle,
																			0
																		);
																		return Promise.resolve();
																	}}>
																	<LinkedinIcon size={32} round />
																</LinkedinShareButton>
															</div>
														</div>

														<div className="blog-post__social-network">
															<div className="blog-post__social-network__share-button">
																<EmailShareButton
																	url={pageUrl}
																	subject={postTitle}
																	body={
																		'Check out this blog post at tech.ao.com: ' +
																		postTitle +
																		' (' +
																		pageUrl +
																		')'
																	}
																	beforeOnClick={() => {
																		sendGtagEvent(
																			'Share Icon',
																			'Email',
																			postTitle,
																			0
																		);
																		return Promise.resolve();
																	}}>
																	<EmailIcon size={32} round />
																</EmailShareButton>
															</div>
														</div>
													</GridItem>
													<GridItem
														key="3"
														span={{ def: 12, md: 4 }}
														className="text-right navigation__next">
														{' '}
														{this.props.pageContext.next && (
															<Link
																className="flex items-center justify-end text-link"
																to={
																	'/post/' + this.props.pageContext.next.slug
																}>
																<span className="mr-4">Next Article</span>
																<Icon
																	icon="chevron-right"
																	label="Next article"
																/>
															</Link>
														)}
													</GridItem>
												</Grid>
											)}
										</Container>
									</div>
								</div>
							</div>
						</Card>
					</article>
				</Container>

				{post.tags &&
					this.props.data.relatedPosts &&
					this.props.data.relatedPosts.edges.length > 0 && (
						<RelatedPosts
							posts={this.props.data.relatedPosts.edges.map((e) => e.node)}
						/>
					)}
			</React.Fragment>
		);
	}
}

export default function (props: PostTemplateProps) {
	return (
		<Layout>
			<PostTemplate {...props} />
		</Layout>
	);
}
