import * as React from 'react';

import ArticleCard from '../components/ArticleCard';
import EventCard from '../components/EventCard';
import { Container, Grid, GridItem } from '@ao-internal/components-react';

export interface ArticleGridProps {
	articles?: WPCard[];
	events?: WPEvent[];
}

export class ArticleGrid extends React.PureComponent<ArticleGridProps, {}> {
	render(): React.ReactNode {
		let articles = this.props.articles;
		let events = this.props.events;

		let stickyPosts;
		if (articles)
			stickyPosts = articles.filter((card) => card.isSticky === true);

		if (articles && stickyPosts && stickyPosts.length > 0) {
			articles = articles.filter((card) => card.isSticky === false);
		}

		return (
			<Container className="px-0 blog-grid">
				<Grid>
					{stickyPosts &&
						stickyPosts.map((card, i) => (
							<GridItem key={i} span={{ def: 12, md: 6, lg: 4 }}>
								<article
									className="h-full blog-grid__sticky-post"
									key={`article-card-${i}`}>
									<ArticleCard
										title={card.title}
										tags={card.tags}
										author={card.author}
										date={card.date}
										excerpt={card.excerpt}
										slug={card.slug}
									/>
								</article>
							</GridItem>
						))}
					{articles &&
						articles.map((card, i) => (
							<GridItem key={i} span={{ def: 12, md: 6, lg: 4 }}>
								<article className="h-full" key={`article-card-${i}`}>
									<ArticleCard
										title={card.title}
										tags={card.tags}
										author={card.author}
										date={card.date}
										excerpt={card.excerpt}
										slug={card.slug}
									/>
								</article>
							</GridItem>
						))}
					{events &&
						events.map((event, i) => (
							<GridItem key={i} span={{ def: 12, md: 6, lg: 4 }}>
								<article className="h-full" key={`event-card-${i}`}>
									<EventCard
										title={event.title}
										excerpt={event.excerpt}
										slug={event.slug}
										date={event.eventFieldGroup.date}
										city={event.eventFieldGroup.city}
										tags={event.tags}
									/>
								</article>
							</GridItem>
						))}
				</Grid>
			</Container>
		);
	}
}

export default ArticleGrid;
