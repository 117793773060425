import * as React from 'react';

import { Link } from 'gatsby';
import { Card, Icon } from '@ao-internal/components-react';

import classnames from 'classnames';

export interface EventCardProps {
	title: string;
	excerpt: string;
	slug: string;
	date: string;
	city: string;
	tags?: {
		nodes: PostTag[];
	};
}

export const EventCard: React.SFC<EventCardProps> = (props) => {
	const eventDate = new Date(props.date);
	const formattedEventDate = eventDate.toLocaleDateString('en-GB', {
		month: 'short',
		day: 'numeric',
		year: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
	});

	const now = new Date();
	const isPastEvent = eventDate < now;

	return (
		<Card
			className={classnames('blog-card h-full', {
				'past-event': isPastEvent,
			})}>
			<div className="blog-card__tags">
				{props.tags &&
					props.tags.nodes.slice(0, 3).map((tag) => (
						<Link
							className="inline-block tag"
							key={tag.name}
							to={'/tag/' + tag.slug}>
							{tag.name}
						</Link>
					))}
			</div>
			<Link to={'/event/' + props.slug} className="blog-card__title-link">
				<h3
					className="text-display-sm"
					dangerouslySetInnerHTML={{ __html: props.title }}
				/>
			</Link>
			{!isPastEvent && (
				<div
					className="blog-card__excerpt"
					dangerouslySetInnerHTML={{ __html: props.excerpt }}
				/>
			)}
			{isPastEvent && (
				<div className="blog-card__excerpt">This is a past event.</div>
			)}
			<div className="blog-card__info">
				<div className="flex items-center author__container">
					<div className="flex-grow event-date">
						<span className="block text-gray-70">
							<Icon
								size="3x"
								icon="calendar"
								className="pr-2 inline-block align-middle"
								label="Date"
							/>
							<span className="inline-block align-middle text-caption">
								{formattedEventDate}
							</span>
						</span>
					</div>
					<div className="flex-shrink event-location">
						<span className="block text-gray-70 text-right">
							<Icon
								size="2x"
								icon="location"
								className="pr-2 inline-block align-middle"
								label="Location"
							/>
							<span className="inline-block align-middle text-caption">
								{props.city}
							</span>
						</span>
					</div>
				</div>
			</div>
		</Card>
	);
};

export default EventCard;
